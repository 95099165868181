<template>
    <v-card class="ma-5">
        <Calendario :tipo="tipo" :horario="horario" :medio="medio"></Calendario>
    </v-card>
</template>

<script>
import Calendario from "../components/Calendario.vue";
export default {
    data(){
        return{
            tipo:[
                {color:"#FFFF00", val : 1, short: "MONTAJE EVENTO", title:"MONTAJE EVENTO"},
                {color:"#8D32A8", val : 7, short: "PREPARAR MATERIAL" ,title:"PREPARAR MATERIAL"},
                {color:"#DB4D81", val : 8, short: "PREPARAR Y LLEVAR", title:"PREPARAR Y LLEVAR MATERIAL"},
                {color:"#A6C9EC", val : 2, short: "LLEVAR MATERIAL", title:"LLEVAR MATERIAL"},
                {color:"#F1A983", val : 3, short: "RECOGER EVENTO", title:"RECOGER EVENTO"},
                {color:"#E87474", val : 6, short: "ALQUILER",title:"ALQUILER"},
                {color:"#42F5E0", val : 9, short: "ÚLTIMOS DETALLES",title:"ÚLTIMOS DETALLES"},
                {color:"#4AB076", val : 10, short: "REUNIÓN",title:"REUNIÓN"},
                {color:"#E49EDD", val : 4, short: "EVENTO (MAI)", title:"REALIZAR EVENTO (MAI)"},
                {color:"#83E28E", val : 5, short: "EVENTO (BDG)", title:"REALIZAR EVENTO (BDG)"},
            ],
            horario:[
                {inicio:"8:00", siglas:"MAÑ", title:"MAÑANA", val : 1},
                {inicio:"11:00", siglas:"COM", title:"COMIDA", val : 2},
                {inicio:"14:00", siglas:"TAR", title:"TARDE", val : 3},
                {inicio:"17:00", siglas:"NOC", title:"NOCHE", val : 4},
                {inicio:"8:00", siglas:"TD", title:"TODO EL DÍA", val : 5},
            ],
            medio:[
                {title:"CRAFTER", val : 1},
                {title:"CHASIS", val : 2},
                {title:"JC", val : 3},
                {title:"TRANSP.", val : 4},
                {title:"CADDY", val : 5},
                {title:"ALQ GRANDE", val : 6},
                {title:"ALQ MED", val : 7},
                {title:"ALQ FRÍO", val : 8},
            ],
        }
    },
    components: {
        Calendario,
    },
};
</script>