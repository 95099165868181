<template>
  <v-card width="100%">
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="mr-4" color="primary" @click="setToday">
              Hoy
            </v-btn>
            <template v-if="type == 'month'">
              <v-btn fab text small color="primary" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar" style="width:130px;text-align: center;">
                {{ $refs.calendar.title.toUpperCase().split(" ")[0] }}
              </v-toolbar-title>
              <v-btn fab text small color="primary" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-btn fab text small color="primary" @click="move(-12)">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title.toUpperCase().split(" ")[1] }}
              </v-toolbar-title>
              <v-btn fab text small color="primary" @click="move(12)">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
            </template>
            <template v-if="type == 'day'">
              <v-btn fab text small color="primary" @click="move(-1)">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                TAREAS DÍA
              </v-toolbar-title>
              <v-btn fab text small color="primary" @click="move(1)">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
            </template>
            <template v-if="type == 'week'">
              <v-btn fab text small color="primary" @click="move(-1)">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                TAREAS SEMANA
              </v-toolbar-title>
              <v-btn fab text small color="primary" @click="move(1)">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Día</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Semana</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet class="pa-2">
          <v-sheet style="overflow-x: scroll;">
            <v-calendar
              @click:event="showTarea"
              @contextmenu:event="showTarea"
              color="primary"
              ref="calendar"
              :style="'min-width:' + sizeToLabel[type]"
              v-model="focus"
              :events="[...tareas]"
              :event-color="(e) => e.color"
              first-interval="8"
              interval-minutes="60"
              interval-count="13"
              :type="type"
              :key="keyHelper"
              :event-height="40"
              :event-more="false"
              :weekdays="weekdays">
              <template v-slot:event="{ event }">
                <Tarea :title="event.explicacion" :tipo="tipo" :horario="horario" :type="type" :medio="medio" :equipoMai="equipoMai" :equipoBdg="equipoBdg" :event="event"/>
              </template>
  
              <template v-slot:day-label="{ day, present, past }">
                <div class="d-flex">
                  <v-btn
                    fab
                    small
                    :elevation="0"
                    :text="!present"
                    :color="present ? 'primary' : ''"
                    :class="{
                      'text-end': true,
                      'grey--text': past,
                      'mr-1': true,
                      'mb-1': true,
                    }"
                  >
                    {{ day }}
                  </v-btn>
                </div>
              </template>
            </v-calendar>
          </v-sheet>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
            transition="slide-y-transition"
          >
            <DetallesTarea
              @reload="getTareas()"
              :tipo="tipo"
              :horario="horario"
              :medio="medio"
              :equipoMai="equipoMai"
              :equipoBdg="equipoBdg"
              :selected-event="selectedTarea"
              :focus="focus"
            />
          </v-menu>
          <div class="d-flex flex-wrap mt-4">
            <v-hover
              v-slot="{ hover }">
              <div style="position: relative; display: inline-block;">
                <v-icon size="40">mdi-help-circle-outline</v-icon>
                <v-card
                  v-if="hover"
                  style="min-width: max-content !important;"
                  class="hover-card"
                  outlined
                  elevation="2">
                    <ul style="list-style: none;" class="pa-2">
                      <li>T: TAREAS</li>
                      <li>E: ESPACIO</li>
                      <li>H: HORARIO</li>
                      <li>M: MEDIOS</li>
                      <li>ALQ: MEDIOS ALQUILER</li>
                      <li>MAI: EQUIPO SALA</li>
                      <li>BDG: EQUIPO BODEGUEROS</li>
                    </ul>
                </v-card>
              </div>
            </v-hover>
            <v-spacer></v-spacer>
            <v-chip class="mx-1" :key="a.val" v-for="a in tipo" :color='a.color' outlined>
              {{ a.short }}
            </v-chip>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" />
    <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
      <v-btn color="primary" class="me-2" fab @click="imprimir" large>
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-btn color="primary" fab @click="addTarea" large>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { parseDate } from "@/utils/index.js";

export default {
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    Tarea: () => import("./Tarea.vue"),
    DetallesTarea: () => import("./DetallesTarea.vue"),
  },
  props: {
    date: Date,
    tipo: Array,
    horario: Array,
    medio: Array,
  },
  data() {
    return {
      check: 0,
      menu: false,
      focus: this.$route.query?.focus || Date.now(),
      tareas: [],
      meses: [{ txt : "ENERO", val : 1},{ txt : "FEBRERO", val : 2},{ txt : "MARZO", val : 3},{ txt : "ABRIL", val : 4},{ txt : "MAYO", val : 5},{ txt : "JUNIO", val : 6},{ txt : "JULIO", val : 7},{ txt : "AGOSTO", val : 8},{ txt : "SEPTIEMBRE", val : 9},{ txt : "OCTUBRE", val : 10},{ txt : "NOVIEMBRE", val : 11},{ txt : "DICIEMBRE", val : 12}],
      mes: null,
      selectedTarea: {},
      selectedElement: null,
      selectedOpen: false,
      typeToLabel: {
        month: "Mes",
        week: "Sem",
        day: "Día",
        "4day": "4Day",
      },
      sizeToLabel: {
        month: "1666px",
        week: "1666px",
        day: "233px",
        "4day": "920px",
      },
      type: "week",
      keyHelper: 0,
      weekdays: [1, 2, 3, 4, 5, 6, 0],
      equipoMai:[],
      equipoBdg:[],
      labelImprimir: {
        month: "MES",
        week: "SEMANA",
        day: "DÍA",
        "4day": "SEMANA",
      },
    };
  },
  async mounted() {
    await Promise.all([
        this.getEquipo(),
        this.getTareas()
    ])
  },
  methods: {
    parseDate,
    async addTarea() {
      this.$router.push({ name: "datosTarea", query:{focus: this.focus} })
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    move(t) {
      this.$refs.calendar.move(t);
    },
    showTarea({ nativeEvent, event }) {
      const open = () => {
        this.selectedTarea = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async getTareas() {
      const { data } = await axios({
        url: "/tareas/",
      });
      this.tareas = data.map((tarea) => {
        tarea.espacio = JSON.parse(tarea.espacio.replace(/'/g, '"'))
        tarea.medio = JSON.parse(tarea.medio.replace(/'/g, '"'))
        tarea.horario = JSON.parse(tarea.horario.replace(/'/g, '"'))
        tarea.equipoMai = JSON.parse(tarea.equipoMai.replace(/'/g, '"'))
        tarea.equipoBdg = JSON.parse(tarea.equipoBdg.replace(/'/g, '"'))
        const start = new Date(tarea.fecha + " " + (this.horario.find(h => h.val == tarea.horario[0])?.inicio || "8:00"));
        const end = new Date(start.getTime() + Math.max((180 * tarea.horario.length * 60 * 1000),180));
        return {
          idTarea: tarea.idTarea,
          color: tarea.tipo ? (this.tipo.find(c => c.val == tarea.tipo).color) : '',
          timed: true,
					date: tarea.fecha,
					tipo: tarea.tipo,
          start: start,
          end: end,
          espacio: tarea.espacio,
          medio: tarea.medio,
          medioAlq: tarea.medioAlq,
          horario: tarea.horario,
          explicacion: tarea.explicacion,
          equipoMai: tarea.equipoMai,
          equipoBdg: tarea.equipoBdg,
        };
      });
    },
    async getEquipo() {
      try {
        const { data } = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_API_URL}/unidades_equipo/montajes`,
        })
        this.equipoMai = data.equipoMai;
        this.equipoBdg = data.equipoBdg;
      } catch (err){
        this.saving = false;
        console.error(err);
        this.$root.$emit("snack", "No se ha podido cargar el listado del equipo");
      }
    },
    imprimir(){
      var v = window.open('', '_blank','')
      v.document.write('<head><title>TAREAS ' + (this.labelImprimir[this.type]) + '</title><style>ul {font-size: 14px !important};html, body { height: auto; }</style></head>');
      var l = v.document.createElement('link');
      l.rel = 'stylesheet';
      l.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
      //titulo
      var wrapper = v.document.createElement('div')
      wrapper.style = "display:block;-webkit-print-color-adjust:exact !important;print-color-adjust:exact !important;"
      wrapper.innerHTML = '<div style="display:flex;justify-content: space-between"><div style="font-family: \'Roboto\', sans-serif;display:flex; align-items:center"><h3>TAREAS ' + (this.labelImprimir[this.type]) + '</h3></div></div><hr/></div>'
      
      var tabla = v.document.createElement("table")
      tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;border:1px solid black; width:100%"
      var thead = v.document.createElement("thead")
      thead.innerHTML = "<tr><td style='border: 1px solid black;text-align:center'>FECHA</td><td style='border: 1px solid black;text-align:center'>TAREA</td><td style='border: 1px solid black;text-align:center'>ESP</td><td style='border: 1px solid black;text-align:center'>Hº</td><td style='border: 1px solid black;text-align:center'>MEDIOS</td><td style='border: 1px solid black;text-align:center'>EXPLICACIÓN</td><td style='border: 1px solid black;text-align:center'>EQ MAI</td><td style='border: 1px solid black;text-align:center'>EQ BDG</td></tr>"
      var tbody = v.document.createElement("tbody")

      var auxTareas = []

      if (this.type == "week"){
        const focusDate = new Date(parseInt(this.focus));

        const dayOfWeek = focusDate.getDay();
        const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
        const monday = new Date(focusDate);
        monday.setDate(focusDate.getDate() + diffToMonday);

        const diffToSunday = 7 - (monday.getDay() || 7);
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + (diffToSunday+1));

        const formattedMonday = `${monday.getFullYear()}-${String(monday.getMonth() + 1).padStart(2, '0')}-${String(monday.getDate()).padStart(2, '0')}`;
        const formattedSunday = `${sunday.getFullYear()}-${String(sunday.getMonth() + 1).padStart(2, '0')}-${String(sunday.getDate()).padStart(2, '0')}`;
        auxTareas = this.tareas.filter(t => {
          const tareaDate = new Date(t.start);
          return tareaDate >= new Date(formattedMonday) && tareaDate <= new Date(formattedSunday);
        });
      }
      else if (this.type == "day"){
        auxTareas = this.tareas.filter(t => new Date(t.start) == new Date(this.focus))
      }
      else if (this.type == "month"){
        auxTareas = this.tareas.filter(t => new Date(t.start).getFullYear() == new Date(this.focus).getFullYear() && new Date(t.start).getMonth() == new Date(this.focus).getMonth())
      }

      auxTareas.filter(t => new Date(t.start) >= new Date(new Date().setHours(0, 0, 0, 0))).sort((a, b) => {
        const dateA = new Date(a.start);
        const dateB = new Date(b.start);
        return dateA - dateB;
      }).forEach(tarea => {
        tbody.innerHTML += "<tr><td style='border:1px solid black'>" + (new Date(tarea.start).toLocaleDateString('esp', { day:'numeric', month:'short' })).toUpperCase() + "</td><td style='border:1px solid black;font-size:x-small;font-weight:bold;text-shadow:-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000;color: " + (tarea.color) + "'>" + (this.tipo.find(t => t.val == tarea.tipo).title) + "</td><td style='border:1px solid black;font-size:x-small'><b>" + (tarea.espacio.join(", ")) + "</b></td><td style='border:1px solid black;font-size:x-small'>" + (this.horario.filter(h => tarea.horario.includes(h.val)).map(h => h.siglas).join(" + ")) + "</td><td style='border:1px solid black;font-size:x-small'>" + (this.medio.filter(h => tarea.medio.includes(h.val)).map(h => h.title).join(" + ")) + "</td><td style='border:1px solid black;font-size:xx-small'>" + (tarea.explicacion) + "</td><td style='border:1px solid black;font-size:x-small;color:blue'>" + (this.equipoMai.filter(h => tarea.equipoMai.includes(h.idUnidadEquipo)).map(h => h.inicialesApellidos || h.nombre).join(" + ")) + "</td><td style='border:1px solid black;font-size:x-small;color: red'>" + (this.equipoBdg.filter(h => tarea.equipoBdg.includes(h.idUnidadEquipo)).map(h => h.inicialesApellidos || h.nombre).join(" + ")) + "</td></tr>"
      });
      tabla.appendChild(thead)
      tabla.appendChild(tbody)
      wrapper.appendChild(tabla)
      v.document.write(wrapper.outerHTML)
      v.print()
      v.close()
    }
  },
};
</script>

<style>
.v-calendar-weekly__day {
  min-height: 80px;
  padding: 3px !important;
}
.v-calendar-weekly__day.v-present {
  border: 1px solid var(--v-primary-base) !important;
}
.v-event {
  height: unset !important;
}
.v-event .v-event--thick {
  height: 25px !important;
}
.v-event-timed{
  overflow-x: hidden;
  overflow-y: auto;
}
.v-calendar-daily__intervals-body{
  display: none;
}
.v-calendar-daily__intervals-head{
  display: none;
}
.hover-card {
  position: absolute;
  top: 40px;
  left: 0;
  width: 500px !important;
  z-index: 10;
}
.v-calendar-daily_head-weekday{
  font-size: x-large !important
}
.v-calendar-daily_head-day-label .v-btn__content{
  font-size: x-large;
}
/*.v-calendar-daily_head-day{
  min-width: 233px !important;
  max-width: 233px !important
}
.v-calendar-daily__day{
  min-width: 233px !important;
  max-width: 233px !important
}*/
</style>